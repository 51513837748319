import React from "react";
import socialStyles from "./socialNetworks.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { graphql, useStaticQuery } from "gatsby";


const SocialNet = () => {

    const data = useStaticQuery (graphql`
    query {
        allContentfulSocialNetworks (sort: { fields: name, order: ASC}) {
            edges {
                node {
                    name
                    link
                }
            }  
        }
    }
    `)

  

    function iconName(socialNetwork) {
        switch(socialNetwork) {
            case "Twitter": return {
                icon: faTwitter,
                className: socialStyles.faTwitter
            };
            case "Facebook": return {
                icon: faFacebook,
                className: socialStyles.faFacebook
            };
            case "Instagram": return {
                icon: faInstagram,
                className: socialStyles.faInstagram
            };
            case "Youtube": return {
                icon: faYoutube,
                className: socialStyles.faYoutube
            };
            default: return {}
            
        }
    }


    

    return (
        <div className={socialStyles.socialMedia}>
            {data.allContentfulSocialNetworks.edges.map((edge) => {

                const socialLink = edge.node.link;
                const socialData = iconName(edge.node.name);
                if (socialLink) {
                    return (
                        <a className={socialStyles.socialIcon} href={socialLink}>
                            <FontAwesomeIcon className={socialData.className} icon={socialData.icon} />
                        </a>
                    )
                } else {
                    return null;
                } 
            })}
        </div>
    )
}

export default SocialNet;