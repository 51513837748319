import React from "react";
import Articles from "../components/post";
import Layout from "../components/layout";
import Newsletter from "../components/newsletter";
import Carousel from "../components/carousel";
import indexStyle from "./index.module.scss"
import MiniBio from "../components/miniBio";
import SocialNet from "../components/socialNetworks";
import Courses from "../components/courses";
import Categories from "../components/postCategories";
import Content from "../components/content";



const IndexPage = () => {
  return (
    <Layout>
      <Content>
        <div className={indexStyle.container}>
          <Carousel/>
          <Newsletter/>
          <div className={indexStyle.content}>
            <div className={indexStyle.main}>
              <div className={indexStyle.position}>
                <h2 className={indexStyle.articlesTitle}>Latest Articles</h2>
                <Articles
                cardItems="2" 
                cardSize="2" />
              </div>
              <div className={indexStyle.position}>
                <h2 className={indexStyle.coursesTitle}>Courses</h2>
                <Courses
                cardSize="2" />
              </div>
            </div>
            
            <div className={indexStyle.sidebar}>
              <MiniBio/>
              <div className={indexStyle.position}>
                <h2 className={indexStyle.sidebarFollow}>Subscribe & Follow</h2>
                <SocialNet/>
              </div>
              <div className={indexStyle.position}>
                <h2 className={indexStyle.sidebarCategories}>Articles categories</h2>
                <Categories />
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default IndexPage;