import React from "react";
import newsletterStyle from "./newsletter.module.scss"

const Newsletter = () => {
    return (
        <div className={newsletterStyle.container}>
            <div className={newsletterStyle.items}>
                <h3 className={newsletterStyle.title}>Subscribe to my Newsletter</h3>
                <p className={newsletterStyle.description}>Be the first to receive the latest buzz on upcoming contests & more!</p>
            </div>
            <div className={newsletterStyle.items}>
                <input className={newsletterStyle.emailBox} type="email" name="email" placeholder="Email Address"></input>
                <input className={newsletterStyle.suscribe} type="submit" value="Subscribe"></input>
            </div>
        </div>
    )
}

export default Newsletter;