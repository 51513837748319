import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import categoriesStyles from "./postCategories.module.scss";

const Categories = () => {

    const data = useStaticQuery (graphql`
    query {
        allContentfulCategories {
            edges{
                node {
                    name
                }
            }
        }
    }
    `)

    return (
        <div className={categoriesStyles.container}>
            <div>
                <ol className={categoriesStyles.cards}>
                    {data.allContentfulCategories.edges.map((edge) => {
                        return (
                            <li className={categoriesStyles.cardItem}>
                                <div className={categoriesStyles.card}>
                                    <Link className={categoriesStyles.link} to={`/category/${edge.node.name}`}>
                                        <p className={categoriesStyles.category}>{edge.node.name}</p>         
                                    </Link>
                                </div>
                            </li>
                        )
                    })}
                </ol>
            </div>
        </div>
    )
}

export default Categories;