import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import carouselStyle from "./carousel.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";


const Carousel = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

    const data = useStaticQuery (graphql`
    query {
        allContentfulEvents (sort: { fields: date, order: ASC}){
            edges{
                node {
                   eventName
                   slug
                   date(formatString:"MMMM DD, YYYY")
                   eventImage {
                     file {
                       url
                       fileName
                     }
                   }
                }
            }
        }
    }
    `)

    return (
        <div className={carouselStyle.container}>
        <Slider {...settings} className={carouselStyle.buttons}>
          {data.allContentfulEvents.edges.map((edge) => {
              return (
                <div>
                    <Link className={carouselStyle.link}  to={`/event/${edge.node.slug}`}>
                        <div >
                            <img  src={edge.node.eventImage.file.url} alt={edge.node.eventImage.file.fileName} />
                        </div>
                        <div className={carouselStyle.events} >
                            <h3 className={carouselStyle.type}>Next Event</h3>
                            <h2 className={carouselStyle.eventName}>{edge.node.eventName}</h2>
                            <p className={carouselStyle.eventDate}>{edge.node.date}</p>
                            <p className={carouselStyle.cta}>Join me!</p>
                        </div>
                    </Link>
                </div> 
              )
          })}  
        </Slider>
      </div>
    )
}

export default Carousel;

