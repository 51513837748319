import React from "react";
import miniBioStyles from "./miniBio.module.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { graphql, useStaticQuery } from "gatsby";

const MiniBio = () => {

    const data = useStaticQuery (graphql`
    query {
        contentfulMiniBio {
          profile {
            file {
              fileName
              url
            }
          }
          name 
          occupation
          bio {
            raw
          }
        }
      }
    `)

    return (
        <div>
            <div className={miniBioStyles.photo}>
                <img className={miniBioStyles.profilePhoto} src={data.contentfulMiniBio.profile.file.url} alt={data.contentfulMiniBio.profile.file.fileName} />
            </div>
            <div>
                <div className={miniBioStyles.short}>
                    <h2 className={miniBioStyles.name}>{data.contentfulMiniBio.name}</h2>
                    <p className={miniBioStyles.occupation}>{data.contentfulMiniBio.occupation}</p>
                    <p className={miniBioStyles.bio}>{renderRichText(data.contentfulMiniBio.bio)}</p>
                </div>
            </div>
        </div>
    )
};

export default MiniBio;